import React from 'react';

import {
  ActionIcon,
  Menu as MantineMenu,
  MenuItemProps as MantineMenuItemProps,
  MenuProps as MantineMenuProps,
  useMantineTheme,
} from '@mantine/core';
import { IconCaretDown } from '@tabler/icons-react';

export interface MenuItemProps extends MantineMenuItemProps {
  title: string;
  onClick?: () => void;
  divider?: 'top' | 'bottom';
  disabled?: boolean;
  invisible?: boolean;
  'data-cy'?: string;
}

export interface MenuProps extends Omit<MantineMenuProps, 'children'> {
  target?: JSX.Element;
  items: MenuItemProps[];
  disabled?: boolean;
  color?: string;
}

const Menu = ({ target, items, disabled = false, color, ...menuProps }: MenuProps): JSX.Element => {
  const theme = useMantineTheme();
  return (
    <MantineMenu shadow='md' {...menuProps}>
      <MantineMenu.Target>
        {target || (
          <ActionIcon
            color={color || 'kiloBlue'}
            variant='filled'
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => e.stopPropagation()}
            disabled={disabled}
          >
            <IconCaretDown size={theme.fontSizes.md} fill={theme.white} />
          </ActionIcon>
        )}
      </MantineMenu.Target>
      <MantineMenu.Dropdown>
        {items.map(
          ({ title, onClick, divider, invisible = false, ...itemProps }) =>
            !invisible && (
              <React.Fragment key={title}>
                {divider && divider === 'top' && <MantineMenu.Divider />}
                <MantineMenu.Item
                  key={title}
                  {...itemProps}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    if (onClick) {
                      e.stopPropagation();
                      onClick();
                    }
                  }}
                >
                  {title}
                </MantineMenu.Item>
                {divider && divider === 'bottom' && <MantineMenu.Divider />}
              </React.Fragment>
            ),
        )}
      </MantineMenu.Dropdown>
    </MantineMenu>
  );
};

export default Menu;
