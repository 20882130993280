import React from 'react';

import { Stack } from '@mantine/core';
import ImageUploader from 'components/ImageUploader';
import { useAuth } from 'context/auth-context';

import KiloStaffAccountForm from './components/KiloStaffAccountForm';

const KiloStaffAccountDetails = (): JSX.Element => {
  const { session } = useAuth();
  const kiloStaff = session && session.kiloStaff ? session.kiloStaff : undefined;

  return (
    <Stack>
      <ImageUploader kiloStaffProp={kiloStaff} />
      <KiloStaffAccountForm />
    </Stack>
  );
};

export default KiloStaffAccountDetails;
