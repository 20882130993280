import { AxiosResponse } from 'axios';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import { UrlParams, PaginatedProfileRetrieveList, Profile } from 'types';
import { client } from 'utils/api-client';
import { encodeUrlParams, urlParamsFromUrl } from 'utils/functions';

interface IUseProfiles {
  user: string | undefined;
  loginEnabled?: boolean;
  role?: Profile['role'];
  athleteGym?: string;
  enabled?: boolean;
  limit?: string;
}

interface QueryKeyType {
  pageParam?: UrlParams;
  queryKey?: (string | boolean | undefined)[];
}

function useProfiles({
  user,
  loginEnabled = true,
  role,
  athleteGym,
  limit = '20',
}: IUseProfiles): UseInfiniteQueryResult<AxiosResponse<PaginatedProfileRetrieveList>> {
  const fetchProfiles = ({
    pageParam = { limit, offset: '0' },
  }: QueryKeyType): Promise<AxiosResponse<PaginatedProfileRetrieveList>> => {
    const params = pageParam;
    params.user = user || '';
    params.login_enabled = loginEnabled.toString();
    if (role) {
      params.role = role;
    }
    if (athleteGym) {
      params.athlete__gym = athleteGym;
    }
    return client.get(`profiles/?${encodeUrlParams(params)}`);
  };

  return useInfiniteQuery(['profiles', user, loginEnabled, role, athleteGym], fetchProfiles, {
    getNextPageParam: page => {
      return page.data.next ? urlParamsFromUrl(page.data.next) : undefined;
    },
    enabled: !!user,
  });
}

export default useProfiles;
