import { useCallback } from 'react';

import { AxiosError, AxiosResponse } from 'axios';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { ErrorMessage } from 'types';
import { client } from 'utils/api-client';

interface ProfilePicture {
  file: File;
  staffId?: string;
  athleteId?: string;
  kiloStaffId?: string;
  gymId?: string;
}

export default function useUploadProfilePicture(): UseMutationResult<
  AxiosResponse<{ url: string }>,
  AxiosError<ErrorMessage>,
  ProfilePicture
> {
  const queryClient = useQueryClient();

  const uploadProfilePicture = useCallback(
    ({
      athleteId,
      staffId,
      kiloStaffId,
      gymId,
      file,
    }: ProfilePicture): Promise<AxiosResponse<{ url: string }>> => {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('file', file);

      if (gymId) {
        return client.post(`gyms/${gymId}/profile-picture/`, formData, headers);
      }

      if (kiloStaffId) {
        return client.post(`kilo-staff/${kiloStaffId}/profile-picture/`, formData, headers);
      }

      if (staffId) {
        return client.post(`staff/${staffId}/profile-picture/`, formData, headers);
      }

      return client.post(`athletes/${athleteId}/profile-picture/`, formData, headers);
    },
    [],
  );

  return useMutation(uploadProfilePicture, {
    onSuccess: () => {
      queryClient.invalidateQueries('user');
    },
  });
}
