import React from 'react';

import { createStyles } from '@mantine/core';
import { Outlet } from 'react-router-dom';

const useStyles = createStyles(theme => ({
  layout: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    background: theme.colors.gray[2],

    '.page': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      maxWidth: theme.breakpoints.sm,
      flexGrow: 1,
      background: theme.colors.gray[1],

      '.header': {
        width: '100%',
        height: '80px',
        background: theme.white,
        padding: theme.spacing.md,
        boxShadow: theme.shadows.xs,
        display: 'grid',
        gridTemplateColumns: '1fr auto 1fr',
        alignItems: 'center',
        gap: theme.spacing.lg,
        marginBottom: '2px',
      },

      '.content': {
        paddingTop: theme.spacing.md,
        paddingBottom: '3rem',
        paddingLeft: '3rem',
        paddingRight: '3rem',
        overflowY: 'auto',
        width: '100%',
        flexGrow: 1,
        [theme.fn.smallerThan('xs')]: {
          paddingLeft: '1rem',
          paddingRight: '1rem',
        },
      },

      '.footer': {
        width: '100%',
        background: theme.white,
        padding: theme.spacing.md,
        boxShadow: theme.shadows.xs,
        marginTop: '2px',
      },
    },
  },
}));

const SalesPortalLayout = (): JSX.Element => {
  const { classes } = useStyles();

  return (
    <div className={classes.layout}>
      <div className='page'>
        <Outlet />
      </div>
    </div>
  );
};

export default SalesPortalLayout;
