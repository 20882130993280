import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';
import { ChangePassword, ErrorMessage } from 'types';
import { client } from 'utils/api-client';

export default function useChangePassword(): UseMutationResult<
  AxiosResponse<void>,
  AxiosError<ErrorMessage>,
  ChangePassword
> {
  return useMutation(request => client.post('/auth/change-password/', request));
}
