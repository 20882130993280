import React from 'react';

import { ActionIcon, Avatar, Checkbox, createStyles, Paper, Text } from '@mantine/core';
import { IconArrowBigRightFilled, IconBarbell } from '@tabler/icons-react';
import { ReactComponent as KiloLogo } from 'assets/svg/long_kilo_blue_white.svg';
import { AxiosError, AxiosResponse } from 'axios';
import { useAuth } from 'context/auth-context';
import { UseMutationResult } from 'react-query';
import { ErrorMessage, ProfileRetrieve } from 'types';
import notificationHandler from 'utils/notifications';

const useStyles = createStyles(theme => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 5fr 1fr',
    gridTemplateRows: 'auto',
    alignItems: 'center',
    gap: theme.spacing.sm,
    padding: theme.spacing.md,
  },
  middle: {
    display: 'grid',
    gridTemplateColumns: 'auto min-content',
    gridTemplateRows: 'auto',
    alignItems: 'center',
    gap: '4px',
  },
}));

interface IProfileCardProps {
  profile: ProfileRetrieve;
  updateDefaultProfileMutation: UseMutationResult<AxiosResponse<string>, AxiosError<ErrorMessage>, string>;
}

const ProfileCard = ({ profile, updateDefaultProfileMutation }: IProfileCardProps): JSX.Element => {
  const { classes } = useStyles();
  const { selectProfile } = useAuth();

  const { successNotification, errorNotification } = notificationHandler();

  const gymTitle = profile.gymTitle || 'Kilo GMS';
  const gymLogo = profile.gymLogo;

  const handleUpdateDefault = (): void => {
    updateDefaultProfileMutation.mutate(profile.profileId, {
      onSuccess: () => {
        successNotification('Default profile successfully updated');
      },
      onError: error => {
        errorNotification('Updating default profile failed', error.response?.data.message);
      },
    });
  };

  return (
    <Paper radius='lg' p={0} shadow='md' withBorder>
      <div className={classes.grid}>
        {gymLogo ? (
          <Avatar alt='Gym Logo' size='100%' src={gymLogo} />
        ) : (
          <Avatar alt='Gym Logo' size='100%'>
            {gymTitle === 'Kilo GMS' ? (
              <KiloLogo height='100%' width={'100%'} />
            ) : (
              <IconBarbell size={'100%'} />
            )}
          </Avatar>
        )}
        <div className={classes.middle}>
          <Text color='dark.4' size='lg' fw='700'>
            {gymTitle}
          </Text>
          <Text color='dimmed' fw='700' size='sm'>
            {profile.role.toUpperCase()}
          </Text>
          <Text>{profile.fullName}</Text>
          <Checkbox
            label='Default'
            checked={profile.isDefault}
            disabled={updateDefaultProfileMutation.isLoading || profile.isDefault}
            onChange={handleUpdateDefault}
          />
        </div>
        <ActionIcon
          size='100%'
          variant='subtle'
          color='kiloBlue'
          onClick={() => selectProfile(profile.profileId)}
        >
          <IconArrowBigRightFilled size='100%' />
        </ActionIcon>
      </div>
    </Paper>
  );
};

export default ProfileCard;
