import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { ErrorMessage } from 'types';
import { client } from 'utils/api-client';

export default function useDeleteAthleteAppointment(): UseMutationResult<
  AxiosResponse<null>,
  AxiosError<ErrorMessage>,
  string
> {
  const queryClient = useQueryClient();

  const deleteAthleteAppointment = (athleteAppointmentId: string): Promise<AxiosResponse<null>> => {
    return client.delete(`athlete-appointments/${athleteAppointmentId}/`);
  };

  return useMutation(deleteAthleteAppointment, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['athleteAppointments'],
      });
      queryClient.invalidateQueries({
        queryKey: ['athleteEvents'],
      });
      queryClient.invalidateQueries({
        queryKey: ['appointments'],
      });
      queryClient.invalidateQueries({
        queryKey: ['events'],
      });
      queryClient.invalidateQueries({
        queryKey: ['totalAttended'],
      });
      queryClient.invalidateQueries({
        queryKey: ['totalReserved'],
      });
    },
  });
}
