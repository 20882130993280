import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';
import { ChangeEmail, ErrorMessage } from 'types';
import { client } from 'utils/api-client';

export default function useChangeEmail(): UseMutationResult<
  AxiosResponse<void>,
  AxiosError<ErrorMessage>,
  ChangeEmail
> {
  return useMutation(request => client.post('/auth/change-email/', request));
}
