import { useCallback } from 'react';

import { AxiosResponse } from 'axios';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import { Document, PaginatedDocumentListList, UrlParams } from 'types';
import { client } from 'utils/api-client';
import { encodeUrlParams, urlParamsFromUrl } from 'utils/functions';

interface QueryKeyType {
  pageParam?: UrlParams;
  queryKey: (string | undefined | null | boolean)[];
}

interface IUseDocuments {
  athleteId?: string;
  search?: string;
  ordering?: string;
  type?: Document['type'];
  isTemplate?: boolean;
  isSigned?: boolean;
  enabled?: boolean;
}

function useDocuments({
  athleteId,
  search,
  ordering,
  type,
  isTemplate,
  isSigned,
  enabled = true,
}: IUseDocuments): UseInfiniteQueryResult<AxiosResponse<PaginatedDocumentListList>> {
  const fetchDocuments = useCallback(
    ({
      pageParam = { limit: '20', offset: '0' },
    }: QueryKeyType): Promise<AxiosResponse<PaginatedDocumentListList>> => {
      const params = pageParam;

      if (search) {
        params.search = search;
      }
      if (ordering) {
        params.ordering = ordering.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`).concat(',pk');
      }
      if (type) {
        params.type = type;
      }
      if (isTemplate !== undefined) {
        params.is_template = isTemplate ? 'True' : 'False';
      }
      if (isSigned !== undefined) {
        params.is_signed = isSigned ? 'True' : 'False';
      }
      if (athleteId) {
        params.athlete = athleteId;
      }

      return client.get(`documents/?${encodeUrlParams(params)}`);
    },
    [search, ordering, type, isTemplate, isSigned, athleteId],
  );

  return useInfiniteQuery(
    ['documents', ordering, search, type, isTemplate, isSigned, athleteId],
    fetchDocuments,
    {
      getNextPageParam: page => {
        return page.data.next ? urlParamsFromUrl(page.data.next) : undefined;
      },
      enabled,
    },
  );
}

export default useDocuments;
