import { useQuery, UseQueryResult } from 'react-query';
import { AthleteRetrieve } from 'types';
import { client } from 'utils/api-client';

function useAthlete(athleteId: string | undefined): UseQueryResult<{ data: AthleteRetrieve }> {
  return useQuery(['athletes', athleteId], () => client.get(`athletes/${athleteId}/`), {
    enabled: !!athleteId,
  });
}

export default useAthlete;
