import React, { useState, useEffect } from 'react';

import { Group } from '@mantine/core';
import { IconBarbell } from '@tabler/icons-react';
import Avatar from 'components/upload/Avatar';
import { useAuth } from 'context/auth-context';
import useUploadProfilePicture from 'hooks/account/useUploadProfilePicture';
import useAthlete from 'hooks/athlete/useAthlete';
import useSelfStaff from 'hooks/staff/useSelfStaff';
import { StaffRetrieve, AthleteRetrieve, KiloStaff, GymRetrieve } from 'types';
import notificationHandler from 'utils/notifications';

type AthleteStaffParam = {
  athleteId?: string;
  staffId?: string;
  kiloStaffId?: string;
  gymId?: string;
};

const uploadParams = (
  athleteUpload: boolean,
  athleteUploadAsStaff: boolean,
  athleteData?: AthleteRetrieve,
  staffData?: StaffRetrieve,
  staffProp?: StaffRetrieve,
  kiloStaffProp?: KiloStaff,
  gymProp?: GymRetrieve,
  athleteProp?: AthleteRetrieve,
): AthleteStaffParam => {
  if (gymProp) return { gymId: gymProp.gymId };
  if (kiloStaffProp) return { kiloStaffId: kiloStaffProp.kiloStaffId };
  if (athleteUploadAsStaff && athleteProp) return { athleteId: athleteProp.athleteId };
  if (athleteUpload) return { athleteId: athleteData?.athleteId };
  if (staffProp) return { staffId: staffProp.staffId };
  return { staffId: staffData?.staffId };
};

const ImageUploader = ({
  athleteProp,
  staffProp,
  kiloStaffProp,
  gymProp,
}: {
  athleteProp?: AthleteRetrieve;
  staffProp?: StaffRetrieve;
  kiloStaffProp?: KiloStaff;
  gymProp?: GymRetrieve;
}): JSX.Element => {
  const { session } = useAuth();
  const { successNotification, errorNotification } = notificationHandler();
  const isStaff = session?.role === 'owner' || session?.role === 'staff';
  const isAthlete = session?.role === 'athlete';

  const uploadMutation = useUploadProfilePicture();
  const [imgUrl, setImgUrl] = useState('');

  const { data: staffData, isLoading: staffLoading, isFetching: staffFetching } = useSelfStaff();

  const {
    data: athleteData,
    isLoading: athleteLoading,
    isFetching: athleteFetching,
  } = useAthlete(session?.athlete?.athleteId);

  // handle getting/setting the profilePicture on component render
  useEffect(() => {
    if (gymProp) {
      setImgUrl(gymProp.profilePicture || '');
      // handle kilo staff account detail view
    } else if (kiloStaffProp) {
      setImgUrl(kiloStaffProp.profilePicture || '');
      // handles staff/owner viewing athlete
    } else if (athleteProp && athleteProp.profilePicture) {
      setImgUrl(athleteProp.profilePicture);
      // handle staff viewing staff drawer
    } else if (staffProp) {
      setImgUrl(staffProp.profilePicture || '');
      // handle PP via details view instead of drawer
    } else if (isStaff && staffData && staffData.data && staffData.data.profilePicture) {
      setImgUrl(staffData.data.profilePicture);
      // handles athletes view
    } else if (isAthlete && athleteData && athleteData.data && athleteData.data.profilePicture) {
      setImgUrl(athleteData.data.profilePicture);
    }
  }, [athleteData, isAthlete, isStaff, staffData, athleteProp, staffProp, kiloStaffProp, gymProp]);

  const handleProfileUpload = (file: File | null): void => {
    if (!file) {
      return;
    }
    if (file.size > 5000000) {
      errorNotification('The selected file is too large. Please select a file smaller than 5MB.');
      return;
    }

    // staff member trying to update an athletes profile picture.
    const athleteUploadAsStaff = isStaff && !!athleteProp;

    const param = uploadParams(
      isAthlete,
      athleteUploadAsStaff,
      athleteData?.data,
      staffData?.data,
      staffProp,
      kiloStaffProp,
      gymProp,
      athleteProp,
    );

    uploadMutation.mutate(
      { ...param, file },
      {
        onError: error => {
          errorNotification('Profile picture upload failed', error.response?.data.message);
        },
        onSuccess: res => {
          successNotification('Profile picture upload success');
          setImgUrl(res.data.url);
        },
      },
    );
  };

  return (
    <Group position='center'>
      {gymProp ? (
        <Avatar
          useBarbell
          imgSrc={imgUrl}
          handleFileChange={files => handleProfileUpload(files[0])}
          loading={uploadMutation.isLoading}
        >
          <IconBarbell size={'100%'} />
        </Avatar>
      ) : (
        <Avatar
          imgSrc={imgUrl}
          handleFileChange={files => handleProfileUpload(files[0])}
          loading={
            uploadMutation.isLoading || staffLoading || staffFetching || athleteLoading || athleteFetching
          }
        />
      )}
    </Group>
  );
};

export default ImageUploader;
