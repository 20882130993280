import React, { useEffect, useState } from 'react';

import { createStyles, Flex, SegmentedControl, Text } from '@mantine/core';
import { Icon as TypeIcon } from '@tabler/icons-react';
import { useNavigate, matchRoutes, useLocation } from 'react-router-dom';

const useStyles = createStyles(theme => ({
  root: {
    backgroundColor: theme.white,
    boxShadow: theme.shadows.md,
    border: `0.5px solid ${theme.colors.gray[2]}`,
    overflowX: 'scroll',
    '.mantine-SegmentedControl-control:not(:first-of-type)': {
      borderWidth: 0,
    },
  },
}));

type NavbarProps = {
  linksData: { Icon: TypeIcon; label: string; route: string }[];
};

const Navbar = ({ linksData }: NavbarProps): JSX.Element | null => {
  const { classes } = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const [pathname, setPathname] = useState<string>('');

  const [links] = useState(() =>
    linksData.map(({ Icon, label, route }) => ({
      label: (
        <Flex direction='column' align='center' gap={2}>
          <Icon size='1.625rem' />
          <Text size='xs' weight={600}>
            {label}
          </Text>
        </Flex>
      ),
      value: route,
    })),
  );

  const handleChange = (value: string): void => {
    navigate(value);
  };

  useEffect(() => {
    const matchedRoutes = matchRoutes(
      linksData.map(route => ({ path: route.route })),
      location,
    );
    if (Array.isArray(matchedRoutes)) {
      setPathname(matchedRoutes[0].route.path);
    }
  }, [location, linksData]);

  return (
    <div className='navbar'>
      <SegmentedControl
        value={pathname}
        color='kiloBlue'
        radius='lg'
        size='sm'
        data={links}
        classNames={classes}
        onChange={handleChange}
        fullWidth
      />
    </div>
  );
};

export default Navbar;
