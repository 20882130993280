import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { ErrorMessage } from 'types';
import { client } from 'utils/api-client';

export default function useDeleteAthleteClass(): UseMutationResult<
  AxiosResponse<null>,
  AxiosError<ErrorMessage>,
  string
> {
  const queryClient = useQueryClient();

  const deleteAthleteClass = (athleteKiloClassId: string): Promise<AxiosResponse<null>> => {
    return client.delete(`athlete-kilo-classes/${athleteKiloClassId}/`);
  };

  return useMutation(deleteAthleteClass, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['athleteClasses'],
      });
      queryClient.invalidateQueries({
        queryKey: ['athleteEvents'],
      });
      queryClient.invalidateQueries({
        queryKey: ['classes'],
      });
      queryClient.invalidateQueries({
        queryKey: ['events'],
      });
      queryClient.invalidateQueries({
        queryKey: ['totalAttended'],
      });
      queryClient.invalidateQueries({
        queryKey: ['totalReserved'],
      });
    },
  });
}
