import React, { useState } from 'react';

import { Button, Group, Modal, PasswordInput, Stack, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useAuth } from 'context/auth-context';
import useChangeEmail from 'hooks/auth/useChangeEmail';
import useChangePassword from 'hooks/auth/useChangePassword';
import { emailValidator, passwordValidator } from 'utils/form-validators';
import notificationHandler from 'utils/notifications';

interface IEditLoginModalProps {
  opened: boolean;
  closeHandler: () => void;
}

interface EmailFormValues {
  email: string;
  newEmail: string;
  reNewEmail: string;
}

interface PasswordFormValues {
  password: string;
  newPassword: string;
  reNewPassword: string;
}

type ModalState = 'view' | 'email' | 'password';

const EditLoginModal = ({ opened, closeHandler }: IEditLoginModalProps): JSX.Element => {
  const { session } = useAuth();
  const { successNotification, errorNotification } = notificationHandler();

  const [state, setState] = useState<ModalState>('view');

  const changeEmailMutation = useChangeEmail();
  const changePasswordMutation = useChangePassword();

  const emailForm = useForm<EmailFormValues>({
    initialValues: { email: '', newEmail: '', reNewEmail: '' },

    validate: {
      email: value => (value ? null : 'Required'),
      newEmail: value => emailValidator(value),
      reNewEmail: (value, values) => (value !== values.newEmail ? 'Emails do not match' : null),
    },
  });

  const passwordForm = useForm<PasswordFormValues>({
    initialValues: { password: '', newPassword: '', reNewPassword: '' },

    validate: {
      password: value => (value ? null : 'Required'),
      newPassword: value => passwordValidator(value),
      reNewPassword: (value, values) => (value !== values.newPassword ? 'Passwords do not match' : null),
    },
  });

  const handleClose = (): void => {
    setState('view');
    emailForm.reset();
    passwordForm.reset();
    closeHandler();
  };

  const handleEmailSubmit = (payload: EmailFormValues): void => {
    if (session?.user.userId) {
      changeEmailMutation.mutate(payload, {
        onSuccess: () => {
          successNotification('Email was successfully updated');
          handleClose();
        },
        onError: (error: any) => {
          errorNotification('Email change attempt failed', error.response?.data.message);
        },
      });
    }
  };

  const handlePasswordSubmit = (payload: PasswordFormValues): void => {
    if (session?.user.userId) {
      changePasswordMutation.mutate(payload, {
        onSuccess: () => {
          successNotification('Password was successfully updated');
          handleClose();
        },
        onError: (error: any) => {
          errorNotification('Password change attempt failed', error.response?.data.message);
        },
      });
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={handleClose}
      size='md'
      centered
      title={<Title order={4}>Edit Login</Title>}
    >
      {state === 'view' && (
        <Stack p='xl' spacing='xl'>
          <Button variant='outline' onClick={() => setState('email')}>
            Change Email
          </Button>
          <Button variant='outline' onClick={() => setState('password')}>
            Change Password
          </Button>
        </Stack>
      )}
      {state === 'email' && (
        <form onSubmit={emailForm.onSubmit(handleEmailSubmit)}>
          <Stack p='md'>
            <TextInput
              {...emailForm.getInputProps('email')}
              label='Current Email'
              placeholder='Enter Current Email'
              type='email'
              required
            />
            <TextInput
              {...emailForm.getInputProps('newEmail')}
              label='New Email'
              placeholder='Enter New Email'
              type='email'
              required
            />
            <TextInput
              {...emailForm.getInputProps('reNewEmail')}
              label='Re-enter New Email'
              placeholder='Re-enter New Email'
              type='email'
              required
            />
          </Stack>

          <Group position='right' pt='md'>
            <Button
              variant='light'
              size='sm'
              radius='md'
              onClick={() => {
                emailForm.reset();
                setState('view');
              }}
            >
              Cancel
            </Button>
            <Button
              data-cy='edit-email'
              type='submit'
              size='sm'
              radius='md'
              loading={changeEmailMutation.isLoading}
            >
              Save
            </Button>
          </Group>
        </form>
      )}
      {state === 'password' && (
        <form onSubmit={passwordForm.onSubmit(handlePasswordSubmit)}>
          <Stack p='md'>
            <PasswordInput
              {...passwordForm.getInputProps('password')}
              label='Current Password'
              placeholder='Enter Current Password'
              required
            />
            <PasswordInput
              {...passwordForm.getInputProps('newPassword')}
              label='New Password'
              placeholder='Enter New Password'
              required
            />
            <PasswordInput
              {...passwordForm.getInputProps('reNewPassword')}
              label='Re-enter New Password'
              placeholder='Re-enter New Password'
              required
            />
          </Stack>

          <Group position='right' pt='md'>
            <Button
              variant='light'
              size='sm'
              radius='md'
              onClick={() => {
                passwordForm.reset();
                setState('view');
              }}
            >
              Cancel
            </Button>
            <Button
              data-cy='edit-password'
              type='submit'
              size='sm'
              radius='md'
              loading={changePasswordMutation.isLoading}
            >
              Save
            </Button>
          </Group>
        </form>
      )}
    </Modal>
  );
};

export default EditLoginModal;
